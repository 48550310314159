export default function FuelInjection(props) {
  const color = props.color || 'black'
  return (
    <svg
      width="407"
      height="307"
      viewBox="0 0 407 307"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.5 25.5H98.5V0.5H257.5V25.5H190.5V40.5H247.5C259.926 40.5 270 50.5736 270 63V89H293.5C305.926 89 316 99.0736 316 111.5V118.5H329.5V111.5C329.5 99.0736 339.574 89 352 89H367.286C375.848 89 383.667 93.8589 387.459 101.535L404.173 135.37C405.704 138.469 406.5 141.879 406.5 145.335V233.334C406.5 236.546 405.812 239.72 404.483 242.644L390.544 273.311C386.893 281.343 378.884 286.5 370.061 286.5H352C339.574 286.5 329.5 276.426 329.5 264V260H316V284.5C316 296.926 305.926 307 293.5 307H173.543C168.386 307 163.385 305.228 159.377 301.981L107.571 260H67.5C55.0736 260 45 249.926 45 237.5V175.75H25V247.5H0V79H25V150.75H45V89C45 76.5736 55.0736 66.5 67.5 66.5H86V63C86 50.5736 96.0736 40.5 108.5 40.5H165.5V25.5ZM111 65.5V69C111 81.4264 100.926 91.5 88.5 91.5H70V235H108.457C113.614 235 118.615 236.772 122.623 240.019L174.429 282H291V257.5C291 245.074 301.074 235 313.5 235H332C344.426 235 354.5 245.074 354.5 257.5V261.5H368.451L381.5 232.792V145.919L365.733 114H354.5V121C354.5 133.426 344.426 143.5 332 143.5H313.5C301.074 143.5 291 133.426 291 121V114H267.5C255.074 114 245 103.926 245 91.5V65.5H111Z"
        fill={color}
      />
    </svg>

  )
}
