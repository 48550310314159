export default function Car(props) {
  const color = props.color || 'black'
  return (
    <svg
      fill={color}
      height="512"
      width="512"
      viewBox="0 0 480 480"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M240 0C107.452 0 0 107.452 0 240s107.452 240 240 240s240-107.452 240-240C479.85 107.514 372.486 0.15 240 0z M455.328 301.552l-15.032-4.888l-4.952 15.2l15.096 4.904c-6.244 17.031-14.534 33.241-24.688 48.272l-12.8-9.28l-9.408 12.944 l12.704 9.232c-11.18 14.27-24.035 27.143-38.288 38.344l-9.232-12.704l-12.944 9.408l9.28 12.8 c-15.03 10.143-31.237 18.422-48.264 24.656l-4.904-15.096l-15.2 4.952l4.888 15.032c-17.449 4.996-35.444 7.841-53.584 8.472V448 h-16v15.8c-18.129-0.633-36.113-3.478-53.552-8.472l4.888-15.032l-15.2-4.952L163.2 450.44 c-17.031-6.244-33.241-14.534-48.272-24.688l9.28-12.8l-12.944-9.408l-9.232 12.704c-14.263-11.191-27.129-24.057-38.32-38.32 l12.704-9.232l-9.408-12.944l-12.8 9.28C44.084 350.009 35.821 333.814 29.6 316.8l15.096-4.904l-4.952-15.2l-15.032 4.888 c-5.01-17.448-7.868-35.443-8.512-53.584H32v-16H16.2c0.633-18.129 3.478-36.113 8.472-53.552l15.032 4.888l4.952-15.2L29.6 163.2 c6.244-17.031 14.534-33.241 24.688-48.272l12.8 9.28l9.408-12.944L63.72 102.04c11.191-14.263 24.057-27.129 38.32-38.32 l9.232 12.704l12.944-9.408l-9.28-12.8C129.968 44.087 146.175 35.821 163.2 29.6l4.904 15.096l15.2-4.952l-4.888-15.032 c17.448-5.01 35.443-7.868 53.584-8.512V32h16V16.2c18.129 0.633 36.113 3.478 53.552 8.472l-4.888 15.032l15.2 4.952L316.8 29.6 c17.031 6.244 33.241 14.534 48.272 24.688l-9.28 12.8l12.944 9.408l9.232-12.704c14.263 11.191 27.129 24.057 38.32 38.32 l-12.704 9.232l9.408 12.944l12.8-9.28c10.135 15.008 18.411 31.19 24.648 48.192l-15.096 4.904l4.952 15.2l15.032-4.888 c4.996 17.449 7.841 35.444 8.472 53.584H448v16h15.8C463.167 266.129 460.322 284.113 455.328 301.552z"
      />
      <path
        d="M240 64c-97.202 0-176 78.798-176 176s78.798 176 176 176s176-78.798 176-176C415.89 142.844 337.156 64.11 240 64z M240 400c-88.366 0-160-71.634-160-160S151.634 80 240 80s160 71.634 160 160C399.899 328.323 328.324 399.899 240 400z"
      />
      <path
        d="M240 104c-75.111 0-136 60.889-136 136s60.889 136 136 136s136-60.889 136-136C375.916 164.924 315.076 104.084 240 104z M342.872 178.536c9.301 15.44 14.972 32.791 16.584 50.744l-50.104-4.048c-4.403-0.37-7.672-4.24-7.301-8.642 c0.176-2.087 1.163-4.022 2.749-5.39L342.872 178.536z M266.712 123.104l-19.328 46.4c-1.7 4.078-6.384 6.006-10.462 4.306 c-1.947-0.812-3.494-2.359-4.306-4.306l-19.328-46.4C230.856 118.965 249.144 118.965 266.712 123.104z M137.128 178.534 L175.2 211.2c3.355 2.875 3.743 7.926 0.868 11.28c-1.373 1.602-3.324 2.597-5.428 2.768l-50.104 4.048 C122.148 211.336 127.822 193.978 137.128 178.534z M204.496 301.392l-11.616 48.944c-16.551-7.145-31.288-17.91-43.128-31.504 h-0.024l42.816-26.12c3.771-2.303 8.694-1.113 10.997 2.658C204.642 297.173 204.985 299.337 204.496 301.392z M287.12 350.336 h-0.024l-11.64-48.944c-1.023-4.298 1.632-8.612 5.93-9.635c2.055-0.489 4.219-0.146 6.022 0.955l42.816 26.12 C318.391 332.423 303.662 343.188 287.12 350.336z M295.76 279.048c-11.315-6.904-26.084-3.329-32.988 7.986 c-3.299 5.407-4.328 11.9-2.86 18.062l12 50.464c-20.868 5.92-42.972 5.92-63.84 0h-0.016l12-50.464 c3.066-12.895-4.902-25.835-17.798-28.901c-6.159-1.464-12.646-0.436-18.05 2.861l-44.272 27.024 c-11.954-18.079-18.752-39.074-19.664-60.728l51.664-4.176c13.21-1.083 23.041-12.671 21.958-25.881 c-0.517-6.299-3.497-12.14-8.294-16.255l-39.36-33.76c13.525-16.922 31.365-29.881 51.64-37.512l19.936 47.864 c5.095 12.236 19.145 18.026 31.382 12.93c5.848-2.435 10.495-7.083 12.93-12.93l19.944-47.864 c20.273 7.633 38.113 20.592 51.64 37.512L294.4 199.04c-10.06 8.631-11.218 23.783-2.587 33.842 c4.12 4.802 9.969 7.782 16.275 8.294l51.664 4.176c-0.912 21.654-7.71 42.649-19.664 60.728L295.76 279.048z"
      />
      <path
        d="M240 208c-17.673 0-32 14.327-32 32s14.327 32 32 32c17.673 0 32-14.327 32-32S257.673 208 240 208z M240 256 c-8.837 0-16-7.163-16-16s7.163-16 16-16s16 7.163 16 16S248.837 256 240 256z"
      />
    </svg>
  )
}
